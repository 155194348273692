<template>
  <v-container fluid>
    <PageNavigation :items="items" />

    <v-row>
      <v-col cols="12">
        <base-material-card
          :title="$vuetify.lang.t('$vuetify.orderInfo')"
          icon="mdi-apple-finder"
        >
          <v-row>
            <v-col cols="12" lg="3" md="6">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.search')"
                append-icon="mdi-magnify"
                hint=""
                @change="(item) => handleSearch(item)"
              />
            </v-col>

            <v-col cols="12" lg="2" md="6">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                :nudge-top="30"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    :label="
                      $vuetify.lang.t('$vuetify.startDate') +
                      ' ~ ' +
                      $vuetify.lang.t('$vuetify.endDate')
                    "
                    prepend-icon=""
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="date = null"
                  />
                </template>

                <v-date-picker
                  v-model="dates"
                  range
                  @change="dateChange"
                  @input="dates.length == 2 ? (menu = false) : true"
                  class="ma-0 pa-0"
                  :locale="
                    this.$vuetify.lang.current == 'zhHans' ? 'zh-cn' : 'en'
                  "
                />
              </v-menu>
            </v-col>

            <v-col cols="12" lg="2" md="6">
              <v-select
                :items="statuses"
                :label="$vuetify.lang.t('$vuetify.status')"
                return-object
                clearable
                @change="(item) => handleStatusFilter(item)"
              />
            </v-col>

            <v-col cols="12" lg="2" md="6">
              <v-select
                :label="$vuetify.lang.t('$vuetify.client')"
                :items="customerDatas"
                :item-text="(item) => item.company"
                @change="(item) => handleCustomerFilter(item)"
                clearable
                return-object
              />
            </v-col>

            <v-spacer />

            <v-col
              v-if="permissionCheck('modify')"
              cols="12"
              lg="1"
              align-self="center"
              class="d-flex justify-end"
            >
              <v-btn
                color="green"
                class="ma-0"
                elevation="2"
                @click="handleDialog"
                :width="$vuetify.breakpoint.mdAndUp ? '' : '100%'"
              >
                <v-icon left>mdi-account-plus</v-icon>
                {{ $vuetify.lang.t("$vuetify.add") }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <OrderTable
                :head="headers"
                :data="datas"
                :search="search"
                :dialog.sync="dialog"
                :tableLoading="tableLoading"
                :data_table_options="data_table_options"
                @view="handleViewDialog"
                @delivery="handleDeliveryDialog"
                @pageChange="handlePageChange"
                @go2Page="handleGo2Page"
              />
            </v-col>
          </v-row>

          <OrderCRUD
            :dialog.sync="dialog"
            :obj.sync="obj"
            :addAction.sync="addAction"
            :orderStatus.sync="orderStatus"
            :modifyStatus.sync="modifyStatus"
            :shippingAddr.sync="shippingAddr"
            :selectedProduct.sync="selectedProduct"
            v-on:selectProduct="handleSelect"
            v-on:paymentDetail="handleDetail"
            v-on:shippingAddress="handleShipping"
            v-on:getData="getData"
            v-on:deleteOrder="handleDeleteOrderDialog"
            v-on:modifyStatus="handleModifyStatusDialog"
            v-on:selectCustomer="handleSelectCustomerDialog"
            v-on:saveForm="saveForm"
            @handlePriceDetailData="handlePriceDetailData"
            @handleAddressData="handleAddressData"
            @handleOrderData="handleOrderData"
          />

          <SelectProduct
            :dialogProduct.sync="dialogProduct"
            :selected.sync="selectedProduct"
          />

          <PaymentDetail
            :dialogDetail.sync="dialogDetail"
            :priceDetail.sync="priceDetail"
          />

          <ShippingAddress
            :dialogShipping.sync="dialogShipping"
            :addressObj="addressObj"
            @getAddressDetail="handleAddressDetail"
          />

          <ModifyStatus
            :dialogStatus.sync="dialogStatus"
            @getStatusData="handleModifyStatus"
          />

          <SelectCustomer
            :dialogCustomer.sync="dialogCustomer"
            @getselectedCustomer="handleSelectCustomer"
          />

          <DialogDelete
            :dialogDelete.sync="dialogDelete"
            :deleteObj="deleteObj"
            @handleDelete="handleDeleteOrderConfirm"
          />
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  orderList,
  orderDelete,
  orderExport,
  orderExports,
  orderUpsert,
} from "@/api/order";
import { customerList } from "@/api/basicdata";

const clone = (obj) => Object.assign({}, obj);
const renameKey = (object, key, newKey) => {
  const clonedObj = clone(object);
  const targetKey = clonedObj[key];

  delete clonedObj[key];
  clonedObj[newKey] = targetKey;
  return clonedObj;
};

const newDelete = () => {
  return {
    id: "",
    customer_id: "", // 客户id
    delivery_addr: "", // {contact:,tel,province,city,district,delivery_addr}
    products: "", // [{id:,nick_name:,number:price:}]
    number: 0, // 商品总数量
    trade_total: 0, // 商品总价格
    pay_way: 1, // 1 在线支付 2 货到付款
    post_way: 1, // 1快递 2自提 3骑手
    pay_path: "", // 账期或者支付方式
    post_price: 0, // 邮费
    rate: "", // 税率
    price: 0, // 订单实付金额  总价格加油费
    currency: "USD", // 币种
    attach: "", // 订单附件[{md5:note}]
    buyer_message: "", // 买家备注
    note: "", // 商家备注
    price_detail: "", // {cash:online:other:} 总价明细
    order_date: "", // 订单时间
    status: "", // 状态 -1 取消 1下单 2待成团 3已支付 6已发货 7 已完成  修改时没有待成团选项
  };
};

const newObj = () => {
  return {
    id: "",
    customer_id: "", // 客户id
    delivery_addr: "", // {contact:,tel,province,city,district,delivery_addr}
    products: "", // [{id:,nick_name:,number:price:}]
    number: 0, // 商品总数量
    trade_total: 0, // 商品总价格
    pay_way: 1, // 1 在线支付 2 货到付款
    post_way: 1, // 1快递 2自提 3骑手
    pay_path: "", // 账期或者支付方式
    post_price: 0, // 邮费
    rate: "", // 税率
    price: 0, // 订单实付金额  总价格加油费
    currency: "USD", // 币种
    attach: "", // 订单附件[{md5:note}]
    buyer_message: "", // 买家备注
    note: "", // 商家备注
    price_detail: "", // {cash:online:other:} 总价明细
    order_date: new Date().toISOString().substr(0, 10), // 订单时间
    status: 1, // 状态 -1 取消 1下单 2待成团 3已支付 6已发货 7 已完成  修改时没有待成团选项
  };
};

const newAddress = () => {
  return {
    contact: "",
    tel: "",
    province: "",
    city: "",
    district: "",
    delivery_addr: "",
  };
};

const newPrice = () => {
  return {
    cash: 0,
    online: 0,
    other: 0,
    remarks: "",
  };
};

const newSearch = () => {
  return {
    have_modify: true,
    skip: 0,
    limit: 10,
    po: "",
    bt: "",
    et: "",
    status: "",
    sell_company: "",
    customer_id: "",
    opt: "",
  };
};

const newTableOption = () => {
  return {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    totalItems: 0,
    footerProps: {
      itemsPerPageOptions: [10, 20, 30, 40, 50],
    },
  };
};

export default {
  name: "OrderList",
  components: {
    OrderTable: () => import("@/components/orders/orderListTable"),
    OrderCRUD: () => import("@/components/orders/OrderCRUD"),
    SelectProduct: () => import("@/components/orders/selectProduct"),
    PaymentDetail: () => import("@/components/orders/paymentDetail"),
    ShippingAddress: () => import("@/components/orders/shippingAddress"),
    PageNavigation: () => import("@/components/pageNavigation"),
    DialogDelete: () => import("@/components/deleteDialog"),
    ModifyStatus: () => import("@/components/orders/modifyStatus"),
    SelectCustomer: () => import("@/components/orders/selectCustomer"),
  },
  data() {
    return {
      orderStatus: "",
      shippingAddr: "",
      search: "",
      menu: false,
      dialog: false,
      dialogProduct: false,
      dialogDetail: false,
      dialogShipping: false,
      dialogDelete: false,
      dialogStatus: false,
      dialogCustomer: false,
      addAction: false,
      modifyStatus: false,
      tableLoading: true,
      priceDetail: newPrice(),
      addressObj: newAddress(),
      obj: newObj(),
      deleteObj: newDelete(),
      searchForm: newSearch(),
      data_table_options: newTableOption(),
      customerDatas: [],
      dates: [],
      datas: [],
      selectedProduct: [],
      statuses: [
        // {
        //   text: this.$vuetify.lang.t("$vuetify.all"),
        //   value: "-100",
        // },
        {
          text: this.$vuetify.lang.t("$vuetify.placeOrder"),
          value: "1",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.daichengtuan"),
          value: "2",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.paid"),
          value: "3",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.shipped"),
          value: "6",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.OutOfStock"),
          value: "7",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.canceled"),
          value: "-1",
        },
      ],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo",
          width: "80",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.orderNo"),
          value: "po",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.client"),
          value: "customer_info.contact",
          sortable: false,
        },
        {
          text: "Total Product Type",
          value: "goods_num",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.goodsTotal"),
          value: "number",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.totalPrice1"),
          value: "trade_total",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.currency"),
          value: "currency",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.orderDate"),
          value: "order_date",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.zhifufangshi"),
          value: "pay_way",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.status"),
          value: "orderStatus",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.actionState"),
          value: "OrderActions",
          align: "center",
          width: "100",
          sortable: false,
        },
      ],

      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.order") },
        { text: this.$vuetify.lang.t("$vuetify.orderList") },
      ],
    };
  },
  methods: {
    getData() {
      orderList(this.searchForm)
        .then((data) => {
          this.tableLoading = false;
          this.data_table_options.totalItems =
            data.total <= 10
              ? 1
              : Math.round(
                  Math.floor(data.total / 10) + (data.total % 10 == 0 ? 0 : 1)
                );

          if (data.items != null) {
            this.datas = [...data.items];

            for (var i = 0; i < this.datas.length; i++) {
              this.datas[i] = renameKey(this.datas[i], "status", "orderStatus");
              this.datas[i].itemNo = i + 1;
              this.datas[i].goods_num = this.datas[i].products.length;
            }
          } else {
            this.datas = [];
            this.$toast.info("No Data Found", { timeout: 2000 });
          }
        })
        .catch((err) => {
          console.log("Order List Error", err);
        });
    },
    getCustomer() {
      customerList()
        .then((data) => {
          this.customerDatas = [...data.items];
        })
        .catch((err) => {
          console.log("Customer List Err", err);
        });
    },
    delCurrentOrder() {
      orderDelete(this.deleteObj.id)
        .then((res) => {
          if (res.meta === 0) {
            this.$toast.success(this.$vuetify.lang.t("$vuetify.deleteSuccess"));
            this.dialog = false;
            this.getData();
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Non-cancelled orders cannot be deleted");
        });
    },
    saveForm(status, data) {
      const array = [];

      data.products.forEach((val) => {
        const obj = {
          id: val.id,
          nick_name: val.nick_name,
          number: Number(val.number),
          price: Number(val.price),
        };
        array.push(obj);
      });

      data.delivery_addr = JSON.stringify(this.addressObj);
      data.price_detail = this.addPriceDetail(this.priceDetail);

      if (this.orderStatus != "") {
        data.status = this.orderStatus;
      }

      data.products = JSON.stringify(array);

      orderUpsert(data)
        .then((res) => {
          if (res.meta == 0) {
            this.$toast.success(this.$vuetify.lang.t("$vuetify.saveSuccess"));
            if (status != "status") {
              this.dialog = false;
              this.getData();

              this.orderStatus = "";
              this.shippingAddr = "";
              this.priceDetail = newPrice();
              this.addressObj = newAddress();
              this.obj = newObj();
              this.deleteObj = newDelete();
              this.searchForm = newSearch();
            }
          }
        })
        .catch((err) => {
          console.log("Order Upload Error", err);
        });
    },
    clearObj() {
      this.obj = newObj();
      this.selectedProduct = [];
      this.priceDetail = newPrice();
    },
    handleDialog() {
      this.dialog = true;
      this.addAction = true;
      this.clearObj();
    },
    handleSelect() {
      this.dialogProduct = true;
    },
    handleDetail() {
      this.dialogDetail = true;
    },
    handleShipping() {
      this.dialogShipping = true;
    },
    handleViewDialog(obj) {
      this.dialog = true;
      this.addToSelectedProduct(obj);
    },
    handleSelectCustomerDialog() {
      this.dialogCustomer = true;
    },
    handleDeliveryDialog(obj) {
      this.$router.push({
        path: "/order/shipping",
        query: {
          id: obj.id,
        },
      });
    },
    handleSelectProduct(obj) {
      this.dialogProduct = false;
      this.selectedProduct = [];

      for (var i = 0; i < obj.length; i++) {
        // obj[i].qty = 0;
        // obj[i].price = 0;
        obj[i].nick_name = obj[i].name;
        obj[i].type = 1;

        this.selectedProduct.push(obj[i]);
        this.selectedProduct = [...new Set(this.selectedProduct)];
      }
    },
    handleUpdateDetail(obj) {
      this.dialogDetail = false;
      this.priceDetail = obj;
    },
    handlePriceDetailData(obj) {
      this.priceDetail = obj;
    },
    handleAddressDetail(obj) {
      this.dialogShipping = false;
      this.addressObj = obj;
      this.setShippingAddress(this.addressObj);
    },
    handleAddressData(obj) {
      this.addressObj = obj;
      this.setShippingAddress(this.addressObj);
    },
    addToSelectedProduct(obj) {
      obj.order_date = obj.gen_time;
      this.obj = obj;
      this.selectedProduct = this.obj.products;
    },
    setShippingAddress(obj) {
      this.shippingAddr =
        obj.contact +
        " (" +
        obj.tel +
        ") " +
        obj.province +
        ", " +
        obj.city +
        obj.district +
        ", " +
        obj.delivery_addr;
    },
    handleSelectCustomer(obj) {
      this.obj = obj[0];
      this.obj = renameKey(this.obj, "id", "customer_id");

      if (obj[0].contacts[0] != undefined) {
        this.setShippingAddress(obj[0].contacts[0]);
        this.addressObj = obj[0].contacts[0];
      } else {
        this.shippingAddr = "";
        this.addressObj = newAddress();
      }
    },
    addPriceDetail(data) {
      const obj = {
        cash: Number(data.cash),
        online: Number(data.online),
        other: Number(data.other),
        remarks: data.remarks,
      };
      return JSON.stringify(obj);
    },
    handleDeleteOrderDialog(obj) {
      this.dialogDelete = true;
      this.deleteObj = obj;
    },
    handleDeleteOrderConfirm() {
      this.dialogDelete = false;
      this.delCurrentOrder();
    },
    handleModifyStatusDialog() {
      this.dialogStatus = true;
    },
    handleModifyStatus(obj) {
      this.dialogStatus = false;
      this.orderStatus = obj;
    },
    handleOrderData(obj) {
      //this.dialog = false;
      this.saveForm("", obj);
    },
    dateChange() {
      this.searchForm.bt = Array.isArray(this.dates) ? this.dates[0] : "";
      this.searchForm.et = Array.isArray(this.dates) ? this.dates[1] : "";

      this.getData();
    },
    handleCustomerFilter(item) {
      if (item != null) {
        this.searchForm.customer_id = item.id;
      } else {
        this.searchForm.customer_id = "";
      }

      this.getData();
    },
    handleStatusFilter(item) {
      if (item != null) {
        this.searchForm.status = item.value;
      } else {
        this.searchForm.status = "";
      }

      this.getData();
    },
    handlePageChange(item) {
      this.searchForm.skip = item.itemsPerPage * (item.page - 1);

      this.getData();
    },
    handleGo2Page(item) {
      if (item != "" || item != 0 || item > this.datas.length / 10) {
        this.data_table_options.page = item;
      } else {
        this.data_table_options.page = 1;
      }

      this.getData();
    },
    handleSearch(item) {
      item != null ? (this.searchForm.po = item) : (this.searchForm.po = "");

      this.getData();
    },
  },
  mounted() {
    this.getData();

    this.getCustomer();
  },
  computed: {
    dateRangeText: {
      get: function () {
        return this.dates.join(" ~ ");
      },
      set: function (value) {
        if (value == null) {
          this.searchForm = newSearch();

          this.getData();
        }
      },
    },
  },
};
</script>